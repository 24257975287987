.suggestWrap {
    background-color: #ffffff;
    position: absolute;
    z-index: 2;
}

.suggestBorder {
    border: 1px solid rgb(217, 217, 217);
}

.casesFormWrap {
    min-width: 300px;
}

.actionBtnWrap {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.pageContainer {
    display: flex;
    padding: 10px;
}

.addButton, .deleteButton, .newButton{
    text-transform: uppercase !important;
}
.deleteButton{
    margin-left: 10px !important;
}

.actionButton {
    display: flex;
    justify-content: space-between;
}
.actionButton button {
   text-transform: uppercase !important;
}

.casesFormML {
    margin-left: 50px;
}

.infoWrap {
    display: flex;
    justify-content: space-between;
}
.taskWrap, .infoWrap, .actionBtnWrap, .casesFormInput{
    margin-top: 15px;
}

.popover {
    height: auto !important;
    max-height: 220px !important;
}

.textField{
    margin-bottom: 10px !important;
}

.casesFormInput{
    border-bottom-color: #4d6081;
}

.preloader {
    color: #6ACAED !important;
    position: fixed;
    top: 50%;
    left: 50%;
}

@media (max-width: 767px) {
    .buttonWrap {
        padding-top: 10px;
    }
    .navigationContainer {
        padding: 0 15px;
    }
    .casesFormWrap {
        width: 100%;
    }
    .pageContainer {
        display: block;
    }
    .casesFormML {
        margin-left: 0;
        margin-top: 50px;
    }
}

