.row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: stretch;
}

.column {
    display: flex;
    align-items: center;
    width: 60px;
}

.columnIcon {
    composes: column;
    width: 26px;
    line-height: 30px;
    text-align: center;
    padding: 0 5px;
}

.iconWrap {
    display: inline-block;
    width: 26px;
    line-height: 45px;
    color: #0072b9;
}

.columnName {
    composes: column;
    font-size: 14px;
    line-height: 16px;
    padding: 0 7px;
    color: #464646;
}

/**/

.head {
    composes: row;
    background-color: #ebebeb;
    border-radius: 1px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, .2);
    position: relative;
    z-index: 1;
}

.headColumn + .headColumn {
    display: none;
}

[data-all-columns-mode] .headColumn + .headColumn {
    display: flex;
}

.headIcon {
    composes: columnIcon;
    max-width: 39px;
    flex-basis: 39px;
    box-sizing: border-box;
    padding: 0 7px;
}

.headIcon + .headName {
    flex-basis: 176px;
    max-width: 176px;
}
.headIcon + .headNameOpen {
    flex-basis: 176px;
    max-width: 176px;
}

.headIconClickable {
    cursor: pointer;
    position: relative;
}

.headIconHide {
    display: none;
}

.headIconWrap {
    composes: iconWrap;
    color: #398bdf;;
    font-size: 22px;
    width: 32px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
}

.headIcon:hover .headIconWrap {
    color: #4f6f84;
}

.headIcon:hover .headIconWrap.arrowBack {
    color: #fff;
}

.headName {
    composes: columnName;
    border-right: solid 1px #d7d7d7;
    color: #004060;
    font-weight: bold;
    flex-basis: 215px;
    max-width: 215px;
    box-sizing: border-box;
}

.headNameOpen {
    composes: columnName;
    border-right: solid 1px #d7d7d7;
    color: #004060;
    font-weight: bold;
    flex-basis: 215px;
    max-width: 215px;
    box-sizing: border-box;
}

@media (max-width: 767px) {
    .headIcon + .headName {
        flex-basis: 176px;
        max-width: 176px;
    }
    .headName {
        flex-basis: 215px;
        max-width: 215px;
    }
    .headNameOpen {
        flex-basis: 290px;
        max-width: 290px;
    }
    .headIcon + .headNameOpen {
        flex-basis: 251px;
        max-width: 251px;
    }
}

@media (max-width: 320px) {
    .headIcon + .headName {
        flex-basis: 176px;
        max-width: 176px;
    }
    .headName {
        flex-basis: 215px;
        max-width: 215px;
    }
    .headNameOpen {
        flex-basis: 230px;
        max-width: 230px;
    }
    .headIcon + .headNameOpen {
        flex-basis: 191px;
        max-width: 191px;
    }
}

.headColumn:not(:nth-of-type(2)) {
    border-left: solid 1px #d7d7d7;
}
.headColumn {
    composes: column;
    flex: 1 1 auto;
    width: 0;
    color: #004060;
    line-height: 14px;
    justify-content: center;
    font-weight: bold;
    text-align: center;
    padding: 6px 5px;
}

.headColumnText {
    display: block;
    white-space: pre-wrap;
    word-break: break-word;
    word-wrap: break-word;
    font-size: 12px;
    width: 100%;
}
.arrowBack {
    color: #004060;
    font-size: 13px;
    font-weight: bold;
}

[data-all-columns-mode] .headColumn {
    flex: 0 1 55px;
    max-width: 55px;
}
[data-all-columns-mode] .headColumn:last-of-type {
    flex: 1 1 auto;
    max-width: none;
}