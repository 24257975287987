.authBlock {
    box-sizing: border-box;
    border-radius: 5px;
    border: 1px solid #48B5E1;
    padding: 20px;
    margin: 0 auto;
    position: absolute;
    left: 0;
    right: 0;
    top: 50px;
    width: 455px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    background-color: #ffffff;
}

.wrapperAuth {
    width: 100% !important;
    height: 110vh;
    position: absolute;
    background: url('/img/background_wrap_auth.png') repeat;
    width: 493px;
    position: relative;
}

@media (max-width: 767px) {
    .authBlock {
        position: relative;
        width: 100%;
        border: none;
        background-color: #F0F0F0;
    }
}

.topText {
    text-align: center;
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 3px;
}

.inputIcon {
    position: absolute;
    top: 0;
    left: 0;
    display: inline-block;
    font-size: 28px;
    line-height: 40px;
    height: 40px;
    width: 40px;
    text-align: center;
    color: #b3c7e7;
}


.logo {
    background: url('/img/Logo_HP_black_2.svg') no-repeat;
    background-size: 148px;
    background-position: center;
    font-size: 50px;
    height: 75px;
    margin-top: 50px;
    margin-bottom: 50px;
    width: 200px;
}

.formError {
    padding: 10px 16px;
    display: none;
    border-left: 4px solid #f8d5d5;
    color: #b13939;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 19px;
}

.formSuccess {
    padding: 10px 16px;
    display: none;
    border-left: 4px solid #b5f8a1;
    color: #0c7e00;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 19px;
}

.formErrorVisible,
.formSuccessVisible {
    display: block;
}

.input {
    border: 1px solid #48B5E1;
    box-sizing: border-box;
    border-radius: 5px;
    line-height: 28px;
    font-size: 16px;
    padding: 5px 70px 5px 15px;
    margin-bottom: 15px;
    color: #272727;
    outline: none;
    min-width: 100% !important;
}

.inputContainer {
    min-width: 70% !important;
}

.inputWrap {
    min-width: 100% !important;
    display: block;
    position: relative;
}

.inputError {
    border-color: #48B5E1;
    background: #ffffff;
}

.inputError,
.inputError + .inputIcon {
    color: #b13939;
}

.btn {
    background: #398bdf;
    border-radius: 5px;
    color: #ffffff;
    font-weight: bold;
    cursor: pointer;
    /* line-height: 36px; */
    white-space: nowrap;
    width: 180px;
    padding: 13px;
}

.containerButton {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    padding: 50px 10px 30px 10px;
}

.restorePass {
    color: #63BBE7 !important;
    margin-top: 7px !important;
    text-transform: uppercase !important;
    display: float;
}
