.emptyTable {

}

.tableHeadRow .tableCell {
    color: #183f5d;
    font-weight: 700;
    text-align: center;
}

.tableHeadRow .tableCell .tableHeaderCellWrap {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
}

.tableHeadRow .tableCellOrderButton {
    color: #183f5d;
    font-weight: 700;
    cursor: pointer;
    padding: calc(var(--table-column-spacing) / 4);
    border-radius: 4px;
    background: #fff;
    transition: background-color 0.2s;
}

.tableCell:hover .tableCellOrderButton:not(.tableCellOrderButtonActive) {
    background: #fff3f3;
}

.tableCell:hover .filterAlt:not(.filterAltActive) {
    background: #fff3f3;
}

.tableCell:hover .tableCellOrderButton:hover {
    background: #ffe5e5;
}

.tableCell:hover .filterAlt:hover {
    background: #ffe5e5;
}

.tableHeadRow .tableCellOrderButton.tableCellOrderButtonActive {
    background: #ffdbdb;
}

.tableHeadRow .filterAlt.filterAltActive {
    background: #ffdbdb;
    color: #265072;
}

.tableCellColumnActive {
    background: #ffebeb;
}

.itemsNotFound {
    box-shadow: 0 0 3px rgba(7, 62, 105, .4);
    text-align: center;
    padding: 30px;
}

.tableHead {
    border-right: 1px solid #d4dff0;
}

.tableRow:hover {
    background-color: #f5fff8;
}

.tableRowStatus {
    background-color: #b9cdf1;
}

.disabledRow {
    color: silver;
    background-color: var(--color-white) !important;
}

.disabledRow:hover {
    color: silver;
    background-color: var(--color-white) !important;
}

.disabledRow .tableCellCheckbox label {
    display: none;
}

.emptyTable .tableCellCheckbox label {
    display: none;
}

.checkboxCell {
    padding-right: 5px;
    width: calc(1.8 * var(--unit));
    display: none;
}

.tableHeadRow .tableCell .filterAlt {
    margin-left: 6px;
    color: #98b3c9;
}

.tableCell {
    border-right: 1px solid #d4dff0;
    padding: calc(var(--table-column-spacing) / 4);
}

.tableCell:first-of-type {
    padding-left: calc(var(--table-column-spacing) / 4);
}

.tableCell:last-of-type {
    padding-right: calc(var(--table-column-spacing) / 4);
}

.tableCell:last-child {
    border-right-color: transparent;
}

.tableContainer {
    overflow-x: auto;
    margin-left: -25px;
    margin-right: -25px;
    padding: 4px 25px 25px;
}

.tableWrap {
    display: inline-block;
}

.tableWrap table {
    box-shadow: 0 0 3px rgba(7, 62, 105, .4);
}

.scrollerWrap {
    position: fixed;
    left: 0;
    bottom: 0;
    right: 0;
    height: 26px;
    overflow-x: auto;
}

.scrollerInner {
    display: inline-block;
    height: 1px;
    background: transparent;
}