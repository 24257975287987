.authBlock {
    box-sizing: border-box;
    border-radius: 5px;
    border: 1px solid #48B5E1;
    padding: 20px 70px;
    margin: 0 auto;
    position: absolute;
    left: 0;
    right: 0;
    top: 50px;
    width: 455px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    background-color: #ffffff;
}

.logo {
    background: url('/img/Logo_hp.png') no-repeat;
    font-size: 50px;
    height: 75px;
    width: 200px;
    margin: 50px auto;
}

.formError {
    padding: 10px 16px;
    display: none;
    border-left: 4px solid #f8d5d5;
    color: #b13939;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 19px;
}

.formSuccess {
    padding: 10px 16px;
    display: none;
    border-left: 4px solid #b5f8a1;
    color: #0c7e00;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 19px;
}

.formErrorVisible,
.formSuccessVisible{
    display: block;
}

.input {
    border: 1px solid #48B5E1;
    box-sizing: border-box;
    border-radius: 5px;
    line-height: 28px;
    font-size: 16px;
    padding: 5px 70px 5px 15px;
    margin-bottom: 15px;
    color: #272727;
    outline: none;
    min-width: 100% !important;
}

.inputContainer {
    min-width: 80% !important;
}

.inputWrap {
    min-width: 100% !important;
    display: block;
    position: relative;
}

.inputIcon {
    position: absolute;
    top: 0;
    left: 0;
    display: inline-block;
    font-size: 28px;
    line-height: 40px;
    height: 40px;
    width: 40px;
    text-align: center;
    color: #b3c7e7;
}

.inputError {
    border-color: #b13939;
    background: rgba(255, 192, 192, 0.18);
}

.inputError,
.inputError + .inputIcon {
    color: #b13939;
}

.btn {
    background: linear-gradient(45deg, #1AA7DF, #0D77B1);
    border-radius: 5px;
    color: #ffffff;
    font-weight: bold;
    cursor: pointer;
    /* line-height: 36px; */
    white-space: nowrap;
    width: 150px;
    padding: 13px;
}


.btnDisabled {
    background-color: #7e9bc9;
    cursor: not-allowed;
}

.containerButton {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 5px;
    padding-bottom: 30px;
}

.restorePass {
    float: left;
    color: #63BBE7 !important;
    border: none !important;
}

.restorePass div span {
    text-transform: none !important;
}
