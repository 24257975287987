.textStyle p {
	font-size: 14px;
	color: rgba(0, 0, 0, 0.54);
}

.textStyle span {
	font-size: 16px;
	color: rgba(0, 0, 0, 0.87);
}

.textStyle * {
	font-family: MuseoSansRounded, sans-serif !important;
}



.textSubStyle {
	display: flex;
	align-items: center;
}
.textSubStyle p {
	font-size: 14px;
	color: rgba(0, 0, 0, 0.54);
}

.textSubStyle span {
	font-size: 14px;
	margin-right: 10px;
	color: #1c5787;
}

.progressIcon {
  color: #fff !important;
}
.popover {
  height: auto !important;
  max-height: 220px !important;
}

.disabledFab:disabled {
  background-color: #e0dfe0 !important;
}