.wrapper {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: block;
    z-index: 9999;
    background: #fff;
    box-sizing: border-box;
    padding-top: 40px;
    transition: .3s top;
}

.hidden {
    display: none;
}

.title {
    font-size: 34px;
    text-align: center;
    margin-bottom: 40px;
}

.close {
    position: absolute;
    top: 20px;
    transition: .3s all;
    right: 20px;
}
.close svg {
    transition: .3s all;
}

.close:hover svg {
    color: #ff2506 !important;
}
.close:hover {
    cursor: pointer;
}

.items {
    height: 600px;
    margin: 0 auto;
    overflow-y: scroll;
}
.items pre {
    height: 200px;
    overflow-y: scroll;
}

.item {
    display: block;
    width: 100%;
    margin-bottom: 20px;
}

.preload {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}