.tableHeadRow .tableCell {
	color: #183f5d;
	font-weight: 700;
	text-align: center;
	padding: calc(var(--table-column-spacing) / 4);
}

.tableHead {
	border-right: 1px solid #d4dff0;
}

.tableRow:hover {
	background-color: #f5fff8;
}

.tableRowStatus {
	background-color: #b9cdf1;
}

.manualCasesStyle {
	background-color: #f2d2d2;
}

.finalCasesStyle {
	background-color: #89ffb0;
}

.tableCell {
	border-right: 1px solid #d4dff0;
	padding: calc(var(--table-column-spacing) / 4);
}

.tableCell:first-of-type {
	padding-left: calc(var(--table-column-spacing) / 4);
}

.tableCell:last-of-type {
	padding-right: calc(var(--table-column-spacing) / 4);
}

.tableCell:last-child {
	border-right-color: transparent;
}

.textCenter {
	text-align: center;
}

.popover {
	height: auto !important;
	max-height: 220px !important;
}

.dialogRoot {
	display: flex;
	align-items: center;
	justify-content: center;
	padding-top: 0;
}

.dialogContent {
	position: relative;
	width: 80vw;
}

.progressIcon {
	display: inline-block;
	width: 24px;
	height: 24px;
	line-height: 24px;
	text-align: center;
	padding: 8px 0;
}

.overlay {
	position: fixed;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	z-index: 9999;
	background: #fff;
	display: flex;
	justify-content: center;
	align-items: center;
}

.planPopup div span {
	color: #1c71b4;
}

.planPopup div {
	width: 100%;
	font-size: 16px;
}

.planPopup {
	display: flex;
	max-width: 768px;
	margin: 0 auto;
	width: 100%;
	flex-wrap: wrap;
}

.planItems {
	overflow-y: scroll;
	max-height: 300px;
	height: 100%;
	margin: 5px 0 0;
}

.planItem span {
	display: block;
}

.planItem {
	display: block;
}

.closeOverlay {
	position: absolute;
	top: 20px;
	right: 20px;
}

.closeOverlay:hover {
	cursor: pointer;
}

.planPopupItem {
	margin-bottom: 20px;
}
