.actionWrap {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
}

.pageWrap {
    padding-bottom: 0;
}

.pageWrapPadding {
    padding-bottom: 66px;
}

.searchWidth > div:first-child {
    width: 100%;
}

.actionWrapSelect {
    min-width: 100% !important;
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
}

.shopsNotFound {
    padding: 60px;
}

.itemsNotFound {
    box-shadow: 0 0 3px rgba(7, 62, 105, .4);
    text-align: center;
    padding: 30px;
}

.selectedContainer {
    display: flex;
    justify-content: start;
    align-items: center;
    color: black;
    position: fixed;
    left: 0;
    bottom: 0;
    right: 0;
    box-shadow: 0 0 3px rgba(7, 62, 105, .4);
    padding: 14px 22px 14px;
    flex-wrap: wrap;
    gap: 20px;
    background: #ffeed4;
}

.processing {
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 900;
    background: rgba(0, 0, 0, 0.1);
}

.taskCreatingResultInfo {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    background: rgba(255, 255, 255, 0.6);
}

.taskCreatingResultInfoBox {
    width: 300px;
    padding: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #b8e3b5;
    border-radius: 10px;
    box-shadow: 0 0 3px rgb(201, 201, 201);
    flex-direction: column;
}

.pageWrap .taskCreatingResultInfoBoxBtn {
    margin-top: 20px;
}

.tableInfo {
    display: flex;
    justify-content: space-between;
    padding-bottom: 14px;
}

.tableInfoCount {
    display: flex;
    width: auto;
    color: #3d3d3d;
    font-size: 14px;
    padding-top: 8px;
    padding-bottom: 8px;
    align-items: center;
}

.tableInfoCount label {
    display: flex;
    align-items: center;
    margin-left: 20px;
    background: #e7e7e7;
    padding-right: 14px;
    border-radius: 8px;
    cursor: pointer;
}

.tableInfoCount span {
    font-weight: bold;
}

.downloadBtn {
    margin-left: 10px;
}

@media (max-width: 767px) {
    .actionWrap {
        flex-direction: column;
        align-items: center;
        margin: 0 20px;
    }
    .actionWrapSelect {
        flex-direction: column;
    }
    .buttonWrapMob {
        display: flex;
        padding-top: 1px;
        margin-top: 12px;
    }
    .itemsNotFound {
        margin: 0 20px;
    }
    .tableInfo {
        flex-direction: row;
    }
    .tableInfoCount {
        flex-direction: column;
        justify-content: start;
        align-items: start;
        margin: 0 20px;
        gap: 20px;
    }
    .tableInfoCount label {
        margin-left: 0;
    }
}