.container {
    padding: 14px;
    position: relative;
    overflow: visible;
}

/**/

.row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: stretch;
    padding: 5px 0;
}

.column {
    display: flex;
    align-items: center;
    width: 60px;
}

.columnIcon {
    composes: column;
    width: 26px;
    line-height: 30px;
    text-align: center;
    padding: 0 5px;
}

.iconWrap {
    display: inline-block;
    width: 26px;
    line-height: 45px;
    color: #0072b9;
}

.columnName {
    composes: column;
    font-size: 14px;
    line-height: 16px;
    padding: 0 7px;
    color: #464646;
}

/**/

.body {
    /**/
}

.bodyWrap {
    overflow-y: auto;
    height: auto;
    max-height: 450px;
}

.scrollBody {
    height: auto;
    max-height: 260px;
}

.scrollBody > div{
    overflow-x: hidden !important;
}
/**/

.item {
    composes: row;
    height: auto;
    background: #fff;
    padding: 5px;
}

.item:not(:last-child) {
    border-bottom: 1px solid #d5e2e9;
}

.itemIcon {
    composes: columnIcon;
    width: 32px;
}

.itemIconClickable {
    cursor: pointer;
}

.itemIconWrap {
    composes: iconWrap;
    color: #70acef;
}

.itemName {
    composes: columnName;
    color: #0071b8;
    /*background: #e8f3fe;*/
    padding: 5px 7px;
    line-height: 14px;
    flex: auto;
    margin-right: 3px;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
    text-align: left;
}

.itemName:hover {
    background: #dce7f2;
}

.itemNameIsActive {
    background: #6bb6ff;
    color: #fff;
}

.itemNameIsActive:hover {
    background: #51a9ff;
    color: #fff;
}

.itemColumn {
    composes: column;
    width: 60px;
    color: #0071b8;
    margin-left: 4px;
    justify-content: center;
    font-weight: bold;
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.itemIcon:hover .itemIconWrap {
    color: #003d5f;
}

.itemColumn + .itemColumn {
    display: none;
}

[data-inner-is-opened] .itemColumn + .itemColumn {
    display: flex;
}

.empty {
    display: block;
    text-align: center;
    padding: 10px;
    font-size: 12px;
}
