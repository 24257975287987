.widgetWrap {
  padding: 0;
}

.widget {
  box-shadow: 0 0 3px rgba(7, 62, 105, 0.4);
  margin: 10px;
  margin-top: 0px;
  padding: 20px;
  height: 120px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: all 500ms;
  border-top: 2px solid #f2f0f0;
}

.widget:hover {
  border-top: 2px solid #398bdf;
  box-shadow: 0 1px 8px rgba(137, 137, 137, 0.5);
}

.name {
  color: #293258;
  font-size: 18px;
}

.unit {
  text-align: right;
}

.unit {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
}

.downloadProgress {
    width: 24px !important;
    height: 24px !important;
}
.menuItemLink {
  color: #000;
}
