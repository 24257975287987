.row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: stretch;
    padding: 5px 0;
}

.search {
    position: absolute;
    top: 11px;
    right: 3px;
    z-index: 2;
    cursor: pointer;
}

.search:hover {
    color: #177BBD;
}

.searchAreaOn {
    display: block;
    padding: 8px 15px 8px 15px;
    width: 85%;
    border: none;
    color: #D3D3D3;
    font-size: 15px !important;
}

.searchAreaOn:focus {
    outline: none !important;
}

.searchAreaOff {
    display: none;
}

.searchWrap {
    position: relative;
    display: block;
    font-size: 25px;
    width: 100%;
    border-top: 0.5px solid #DDDDDD;
    background-color: #ffffff;
}

.searchWrapNone {
    display: none;
}

.searchOnWrap {
    position: absolute;
    top: 10px;
    right: 3px;
    color: #AAAAAA;
    z-index: 2;
    cursor: pointer;
}

.searchOnWrap:hover{
    color: #177BBD;
}

.column {
    display: flex;
    align-items: center;
    width: 60px;
}

.columnIcon {
    composes: column;
    width: 26px;
    line-height: 30px;
    text-align: center;
    padding: 0 5px;
}

.columnIcon:hover {
    background-color: #e0e0e0;
}

.iconWrap {
    display: inline-block;
    width: 26px;
    line-height: 45px;
    color: #0072b9;
}

.columnName {
    composes: column;
    font-size: 14px;
    line-height: 16px;
    padding: 0 7px;
    color: #464646;
}

/**/

.panel {
    composes: row;
    background-color: #ffffff;
    box-shadow: 0 1px 2px rgba(137, 137, 137, .5);
    /*height: 40px;*/
    padding: 0;
    position: relative;
    z-index: 2;
}

.panelActive {
    background-color: #398bdf;
}

.panelIcon {
    border-right: 1px solid #dddddd;
    composes: columnIcon;
    cursor: pointer;
    padding: 0;
    text-align: center;
    width: 40px;
}

.panelActive .panelIcon {
    border-right-color: rgba(255, 255, 255, .25);
}

.panelIconWrap {
    composes: iconWrap;
    color: #7d7d7d;
    font-size: 30px;
    line-height: 40px;
    width: 40px;
}

[data-content-is-opened] .panelIconWrap {
    color: #737373;
}

.panelIcon .panelIconWrap {
    color: #7d7d7d;
}

.panelActive .panelIconWrap {
    color: #ffffff;
}

.panelActive .columnIcon:hover {
    background-color: #398bdf;
}

.panelActive .columnIcon:hover .panelIconWrap {
    color: #ffffff;
}

.panelIcon:hover .panelIconWrap {
    color: #494949;
}

.panelButton {
    margin-left: 7px;
    margin-top: 3px;
    color: #1c70b4;
    font-size: 12px;
    font-weight: bold;
    opacity: 0.8;
    cursor: pointer;
}

.panelButton:not(:disabled):hover {
    opacity: 1;
}

.panelButton:disabled {
    opacity: 0.5;
    cursor: default;
}

.panelName {
    composes: columnName;
    position: relative;
    font-size: 18px;
    line-height: 45px;
    color: #3d3d3d;
    flex: auto;
    padding-left: 16px;
}

.panelNameSimple {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}

.panelActive .panelName {
    color: #fff;
}

.panelColumn {
    composes: column;
    color: #004060;
    margin-left: 4px;
    justify-content: center;
    cursor: pointer;
    width: 40px;
}

.panelColumn:hover {
    background: #e0e0e0;
}

.panelActive .panelColumn:hover {
    background: #398bdf;
}

.paper {
    border-radius: 0 !important;
    margin: 5px 0;
    width: 100%;
}

.input {
    padding: 0 5px;
    width: calc(100% - 44px);
}

.iconButton {
    padding: 5px !important;
}