.pagination {
    display: flex;
    justify-content: flex-end;
    padding-left: 0;
    list-style: none;
    border-radius: 0.25rem;
}

.pageItem:first-child .pageLink {
    margin-left: 0;
    border-bottom-left-radius: 0.25rem;
    border-top-left-radius: 0.25rem;
}

.pageItem:last-child .pageLink {
    border-bottom-right-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
}

.pageItem.active .pageLink {
    z-index: 2;
    color: #89a0c7;
    background-color: #e5efff;
}

.pageItem.disabled .pageLink {
    color: #636c72;
    pointer-events: none;
    cursor: not-allowed;
    background-color: #fff;
    border-color: #ddd;
}

.pageLink {
    position: relative;
    display: block;
    padding: 1rem 1.5rem;
    margin-left: -1px;
    line-height: 1.25;
    color: #1c71b4;
    background-color: #fff;
    border: 1px solid #d4dff0;
}

.pageLink.disabled {
    color: #636c72;
    pointer-events: none;
    cursor: not-allowed;
    background-color: #fff;
    border-color: #ddd;
}

.pageLink:focus, .pageLink:hover {
    cursor: pointer;
    color: #014c8c;
    text-decoration: none;
    background-color: #eceeef;
    border-color: #ddd;
    outline-width: 0;
}

.breakLabel {
    pointer-events: none;
}

.flex {
    display: flex;
}

.customPagination {
    display: flex;
    margin: 0 0 20px 20px;
}

.customPagination input {
    width: 60px;
}

.customPagination input, .customPagination button {
    font-size: 13px;
    font-weight: 500;
}

input.numberInput[type="number"]:-ms-input-placeholder {
    color: #1c71b4;
    opacity: 1;
}

input.numberInput[type="number"]::-ms-input-placeholder {
    color: #1c71b4;
    opacity: 1;
}

input.numberInput[type="number"]::placeholder,
input.numberInput[type="number"]::-ms-input-placeholder {
    color: #1c71b4;
    opacity: 1;
}

@media screen and (max-width: 876px) {
   .paginationBlock {
       width: 100%;
       flex-wrap: wrap;
   }
    .paginationBlock .customPagination {
        width: 100%;
        height: 40px;
        margin: 20px 0 50px 0;
        justify-content: center;
    }
    .paginationBlock .pagination .pageItem {
        display: flex;
        flex-grow: 1;
        flex-basis: 0;
        align-items: center;
        justify-content: center;
    }
    .paginationBlock .pagination .pageLink {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        display: flex;
        padding: initial;
        justify-content: center;
        align-items: center;
    }
    .paginationBlock .pagination {
        width: 100%;
        height: 40px;
    }
}