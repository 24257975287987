header {
  /*background-color: #398bdf;*/
  background: linear-gradient(45deg, #4096ed, #021B79);
}

.container {
  padding: 10px 0;
  margin: 0 25px;
  /*background-color: #398bdf;*/
}

.headerWrap {
  padding-left: 0;
  padding-right: 0;
}

.logoWrap {
  position: relative;
  display: inline-flex;
  padding-right: 50px;
}

.logo {
  width: 176px;
  height: 70px;
  display: flex;
  padding-top: 9px;
}

.profileWrap {
  line-height: 1;
  max-width: 50%;
  display: flex;
  align-items: center;
}

.companyName {
  color: #398bdf;
}

.logoutWrap {
  border-top: 1px solid #e0e0e0;
  line-height: 26px;
  display: flex;
  flex-direction: column;
}

.button {
  align-items: center;
  border-left: 1px solid #e0e0e0;
  cursor: pointer;
  display: flex;
  float: right;
  padding: 5px 10px;
  text-align: left;
  width: 100%;
}

.settingsButton {
  border: 1px solid #58cffd;
  border-radius: 3px;
  opacity: 1;
}

.row {
	color: #636363;
	width: 100%;
}

.tableWrap {
	border: 1px solid #c4c4c4;
	border-radius: 3px;
}

.viewTable {
	width: 100%;
	border-collapse: collapse;
	border-radius: 5px;
	overflow: hidden;
	/* border: 1px solid black; */
}

.left {
	width: 50%;
	background-color: #ffffff;
	padding: 7px 7px 7px 50px;
	border: 1px solid #e6e8e9;
	font-size: 1.2em;
	color: #000000;
}

.right {
	width: 50%;
	background-color: #f7f8f8;
	padding: 5px 5px 5px 50px;
	border: 1px solid #e6e8e9;
	font-size: 1.2em;
	color: #000000;
}

.noUser {
	width: 100%;
	background-color: #f8c4c4;
	padding: 7px 5px 7px 50px;
	border: 1px solid #e6e8e9;
	font-size: 1.2em;
	color: #000000;
}

.exitText {
  color: #398bdf;
  font-size: 14px;
  margin-left: 5px;
  text-decoration: underline;
  cursor: pointer
}

.userName,
.companyName {
  font-size: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.userName {
  color: rgba(0, 0, 0, 0.87);
}

.personalInfoBlockWrap {
  position: relative;
  z-index: 11;
  background: #fff;
}

.personalInfoItem {
  padding-top: 13px !important;
  padding-bottom: 13px !important;
}

.cf::after {
  display: block;
  content: '';
  clear: both;
}

.avatarWrap {
  border-radius: 50%;
  height: 48px;
  position: relative;
}

.userData:after {
  content: '';
  display: block;
  width: 1px;
  height: 42px;
  background: #fff;
  top: 50%;
  position: absolute;
  transform: translateY(-50%);
  right: -18px;
}

.profile {
  display: flex;
  color: #ffffff;
  float: right;
  padding: 5px 0;
  position: relative;
  line-height: 20px;
  align-items: center;
}

.avatar {
  background-color: #fff;
  border-radius: 50%;
  height: 38px;
  width: 38px;
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}

.avatarSvg {
  background-color: #fff !important;
  color: #398bdf !important;
}

@media (max-width: 767px) {
  .logo {
    height: 50px;
  }

  .logoText {
    display: none;
  }

  .logo img {
    width: 130px;
    height: 49px;
  }

  .logoTextItem {
    white-space: nowrap;
  }

  .container {
    /*width: 100%;*/
    padding: 10px 0;
    line-height: 1;
    margin: 0;
  }

  .headerWrap {
    padding-left: 15px;
    padding-right: 15px;
  }

  .profile {
    background-color: transparent;
    border: none;
    padding: 0;
    float: none;
    line-height: 1;
    display: flex;
    align-items: center;
  }

  .button {
	background-color: transparent;
	cursor: pointer;
  }

  .headerRow {
    display: flex;
    justify-content: center;
    height: 52px;
  }

  .logoWrap {
    padding-right: 10px;
    height: 52px;
  }

  .invisibleOne {
    width: 0;
    height: 52px;
  }

  .avatarWrap {
    margin-left: auto;
  }

  .profileWrap {
    display: flex;
    margin-left: auto;
    overflow: visible;
  }
}

.userData:after {
  content: '';
  display: block;
  width: 1px;
  height: 42px;
  background: #fff;
  top: 50%;
  position: absolute;
  transform: translateY(-50%);
  right: -18px;
}

.userData {
  margin: 0 22px 0 auto;
  text-align: right;
  position: relative;
}

@media (max-width: 424px) {
  .userData {
    display: none;
  }
}

.userDataName {
  font-size: 18px;
  color: #fff;
  margin-bottom: 8px;
}

.userDataCompany {
  font-size: 14px;
  color: #fff;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.invisibleOne:hover {
  cursor: pointer;
}

.invisibleOne {
  width: 50px;
  display: block;
  right: 0;
  top: 0;
}

@media (max-width: 767px) {
  .logoText {
    display: none;
  }

  .logo img {
    width: 130px;
    height: 49px;
  }

  .logoTextItem {
    white-space: nowrap;
  }

  .container {
    width: 100%;
    padding: 10px 0;
    line-height: 1;
  }

  .headerWrap {
    padding-left: 15px;
    padding-right: 15px;
  }

  .profile {
    background-color: transparent;
    border: none;
    padding: 0;
    float: none;
    line-height: 1;
    display: flex;
    align-items: center;
  }

  .button {
    background-color: transparent;
  }

  .headerRow {
    display: flex;
    justify-content: center;
    height: 52px;
  }

  .logoWrap {
    padding-right: 10px;
    height: 52px;
  }

  .invisibleOne {
    width: 0;
    height: 52px;
  }

  .avatarWrap {
    margin-left: auto;
  }

  .profileWrap {
    display: flex;
    margin-left: auto;
    overflow: visible;
    justify-content: end;
  }
}

/* @media (max-width: 1240px) and (max-width: 800px) { */
@media (min-width: 426px) and (max-width: 1240px) {
	.container {
	  /*width: 1240px;*/
	  /* margin: 0 auto; */
	}
  }
