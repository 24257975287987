.containerRow {
    margin-right: 0;
    margin-left: 0;
    display: flex;
    flex-direction: row;
}

.containerRowGroup {
    display: block !important;
}

.customContainerRow {
    overflow: visible;
    display: block;
}

.customContainerRow:after {
    content: "";
    clear: both;
    display: table;
}

.scenarioContainer {
    display: flex;
    flex-wrap: wrap;
}