.headColor {
    color: #f00;
}

.containerGrid {
    padding: 10px;
}

.containerRow {
    margin-right: 0;
    margin-left: 0;
}

.scenariosCol {
    margin: 10px;
    background-color: #1c71b4;
    color: #ffffff;
    font-size: 2.4rem;
    line-height: normal;
    padding: 30px;
    text-align: center;
    max-width: calc(33.3% - 20px);
}

.scenariosWrap {
    padding: 10px;
}

.scenariosBlock {
    background-color: #1c71b4;
    color: #ffffff;
    font-size: 2.4rem;
    line-height: normal;
    padding: 30px;
    text-align: center;
}

.tableWrap {
    box-shadow: 0 0 3px rgba(7, 62, 105, .4);
}

.tableWrapEmpty {
    box-shadow: 0 0 3px rgba(7, 62, 105, .4);
    text-align: center;
    padding: 30px;
}

.contentWrap {
    margin-top: -10px;
}

.bottomBlock {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
}

.actionWrap {
    align-items: flex-start !important;
    display: flex !important;
    justify-content: space-between !important;
    margin-bottom: 2rem;
}

.buttonWrap {
    display: flex;
    /*max-height: 38px;*/
    right: 30px;
    position: absolute;
    top: 0; 
    margin: 2px 10px 14px 10px; 
    padding: 11px 0;
}

.button {
    box-shadow: rgba(0, 0, 0, 0) 0 0 0 !important;
    border: 1px solid #d4dff0;
    border-top: 0px solid #d4dff0;
}

.buttonActive button div span {
	color: #ffffff !important;
}

.buttonGroupWrap {
    display: flex;
    justify-content: flex-end;
    padding-left: 0;
    list-style: none;
    border-radius: 0.25rem;
}

.buttonLink:hover {
    background-color: rgba(0, 0, 0, .08);
}

.actionWrapSelect {
    min-width: 100% !important;
}

.buttonLink:first-child {
    margin-left: 0;
    border-bottom-left-radius: 0.25rem;
    border-top-left-radius: 0.25rem;
}

.buttonLink:last-child {
    border-bottom-right-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
}

.buttonLink.active {
    z-index: 2;
    color: #89a0c7;
    background-color: #e5efff;
}

.buttonLink {
    position: relative;
    display: block;
    padding: 1rem 1.5rem;
    margin-left: -1px;
    line-height: 1.25;
    color: #1c71b4;
    background-color: #fff;
    border: 1px solid #d4dff0;
}

.navigationContainer {
    padding: 10px;
}

.empty {
    display: block;
    text-align: center;
    padding: 80px 20px;
    font-size: 14px;
    color: black;
}

.button span{
    color: #1C71B4 !important;
}

.taskNoFound{
    margin-top: 10px;
}


@media (max-width: 767px) {
    .actionWrapSelect {
        min-width: 100% !important;
    }
    .tableWrap {
        overflow: hidden;
    }
    .tableWrapEmpty {
        margin: 0 20px;
    }
    .navigationContainer {
        padding: 0 15px;
    }
    .actionWrap {
        display: block;
        margin: 0 20px;
    }
    .actionWrapSelect {
        min-width: 100% !important;
        display: block !important;
        padding-bottom: 10px;
    }
    .buttonGroupWrap {
        display: block;
        text-align: center;
        width: 100%;
    }
    .tableContainer {
        padding: 10px;
    }
    .buttonLink {
        font-size: 16px;
        padding: 12px;
        font-weight: bold;
    }
    .buttonWrapMob {
        width: 100%;
        display: flex;
        height: 55px;
        flex-direction: row;
        justify-content: center;
        position: fixed;
        bottom: 0;
        left: 0;
        padding-top: 4px;
        background-color: #ffffff;
        z-index: 4;
        padding-bottom: 5px;
        box-shadow: 0px -3px 10px -4px rgba(64,64,64,1);
        margin-top: 7px;
    }
    .bottomBlock {
        justify-content: center;
    }
    .button {
        border: none;
        margin: 0 auto;
        align-items: center;
        color: #000000;
    }
    .button span{
        position: absolute !important;
        left: 0;
        bottom: -30px;
        right: 0;
        padding-left: 0 !important;
        padding-right: 0 !important;
        color: #000000 !important;
    }
    .buttonActive button div span {
        color: #000000 !important;
    }
    .buttonActive {
        fill: #47B3E7 !important;
    }
    .button button {
        background-color: transparent !important;
    }
    .button button > div > div {
        height: 50px !important;
        line-height: 50px !important;
    }
    .button button > div > div span {
        font-size: 8px!important;
        font-weight: bold !important;
    }
    .menuItemMob {
        font-size: 12px !important;
    }
}