.searchWrap {
    display: flex;
    /*justify-content: flex-end;*/
    padding-left: 0;
    list-style: none;
    border-radius: 0.25rem;
    position: relative;
    /*max-width: 200px;*/
}

.button {
    background-color: #4e6181;
    border-radius: 3px;
    color: #ffffff;
    padding-left: 20px;
    padding-right: 20px;
    margin-left: 10px;
}

.searchIcon {
    position: absolute;
    top: 17px;
    right: 3px;
    color: #B0B0B0;
}
