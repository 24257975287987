.defaultIcon {
	display: block;
}
.svgIcon{
	display: inline;
	width: 30px;
}
@media (min-width: 767px) {
	.svgIcon{
		display:none !important;
	}
}
