.headColor {
    color: #f00;
}

.containerGrid {
    padding: 0 10px;
}

.containerRow {
    margin-right: 0;
    margin-left: 0;
}

.flexRowWrap {
    position: relative;
}

.breadcrumb {
    display: flex;
    flex-wrap: wrap;
    padding: 0.75rem 1rem;
    margin-bottom: 1rem;
    list-style: none;
    background-color: #f8f8f8;
    border-radius: 0.25rem;
}

.breadcrumb-item:not(:first-child) {
    padding-left: 0.5rem;
}

.breadcrumb-item:not(:first-child):before {
    display: inline-block;
    padding-right: 0.5rem;
    color: #6c757d;
    content: "/";
}

.breadcrumb-item.active {
    color: #6c757d;
}

.breadcrumb-item > a {
    cursor: pointer !important;
    color: #398bdf !important;
}

.scenariosCol {
    margin: 10px;
    background-color: #1c71b4;
    color: #ffffff;
    font-size: 2.4rem;
    line-height: normal;
    padding: 30px;
    text-align: center;
    max-width: calc(33.3% - 20px);
}

.scenariosWrap {
    padding: 10px;
}

.scenariosBlock {
    background-color: #1c71b4;
    color: #ffffff;
    font-size: 2.4rem;
    line-height: normal;
    padding: 30px;
    text-align: center;
}

.filtersWrap {
    background-color: #f7f7f7;
    border: 1px solid #e7e7e7;
    padding: 0;
}

.contentWrap {
    position: relative;
    align-self: flex-start;
}

.contentWrapRedash {
    align-self: flex-start;
}

.contentPreload {
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.6);
    z-index: 99;
}

.contentPreloadReport {
    position: absolute;
    left: 0;
    top: 0;
    min-height: calc(100vh - 92px);
    display: flex;
    justify-content: center;
    align-items: center;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 1);
    z-index: 100;
}


.pagePreload {
    display: block;
    text-align: center;
    padding: 60px;
    font-size: 16px;
    line-height: 24px;
    color: gray;
}

.empty {
    display: block;
    text-align: center;
    padding: 0;
    font-size: 14px;
    color: black;
}

