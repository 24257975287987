.container {
    padding: 0 14px 14px;
    position: relative;
    overflow: visible;
}

.datePickerWrap {
    background-color: #398bdf;
    border-top: 10px solid #398bdf;
    box-shadow: 0 1px 2px rgba(137, 137, 137, .5);
    padding: 10px;
}

.datePicker > div:first-child {
    height: 24px !important;
}

.datePickerRow {
    color: #004a61;
    margin-right: 0;
    margin-left: 0;
    cursor: default;
}

.datePickerCol {
    margin-top: -1px;
    margin-bottom: 2px;
}

.popoverWeekWrap {
    display: flex;
    justify-content: space-between;
    width: 480px;
}

.popoverWeekWrapMob {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    overflow: hidden;
}

.popoverWeekLeft {
    background-color: #398bdf;
    color: rgba(255, 255, 255, .7);
    padding: 10px;
    width: 140px;
}

.popoverWeekLeftMob {
    background-color: #398bdf;
    color: rgba(255, 255, 255, .7);
    padding: 10px;
    width: 100%;
    display: flex;
    align-items: center;
}

.popoverWeekLeftMob p:first-child {
    font-size: 3em;
    color: #ffffff;
}

.popoverWeekLeft p {
    font-size: 16px;
    margin-bottom: 10px;
}

.popoverWeekLeft p {
    font-size: 16px;
    margin-bottom: 10px;
}

.popoverWeekRight {
    padding: 10px;
    width: 300px;
}


.popoverWeekRightMob {
    padding: 10px;
    width: 95%;
}

.weeksWrap {
    overflow-y: auto;
    max-height: 330px;
}

.selectedYear {
    color: #ff0000;
}

.slideWrap {
    display: flex;
    font-size: 14px;
    justify-content: space-between;
    line-height: 30px;
}

.slideButton {
    cursor: pointer;
    font-size: 14px;
    width: 30px;
}

.slideButtonDisabled {
    color: #cccccc;
}

.weekItem {
    border: 1px solid #63d0f5;
    cursor: pointer;
    display: block;
    font-size: 12px;
    margin: 2px 0;
    text-align: center;
    width: 100%;
    padding: 4px;
    border-radius: 3px;
}

.weekItemNow {
    color: #00bcd4;
}

.weekItemActive {
    background-color: #398bdf;
    color: #ffffff;
}

.dateButton {
    cursor: pointer;
    color: #ffffff;
    font-size: 1.3rem;
    font-weight: inherit;
    margin-top: 3px;
}

.disabledButton {
    cursor: default;
    opacity: .5;
}
@media screen and (max-width: 767px) {
    .fromToWrap {
        display: flex !important;
        flex-basis: 75%;
    }
    .iconToWrap {
        flex-basis: 15%;
    }
    .datePickerCol {
        margin-top: 0;
        margin-bottom: 3px;
        padding-right: 0;
    }
    .dateButton {
        margin-top: 5px;
    }
    .datePickerRow:first-child {
        margin-top: 4px !important;
        min-width: 35px;
        flex-basis: 35px;
    }
}

@media screen and (max-width: 321px) {
    .dateButton {
        margin-top: 5px;
    }
}
