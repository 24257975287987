.item {
    width: 100%;
    margin: 0 auto;
    display: block;
    max-width: 1200px;
}

.linkSpan {
    max-width: 55%;
}

.itemPreview {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    word-wrap: break-word;
}
.itemPreview * {
    font-size: 18px;
}

.itemPreview .status {
    padding: 5px;
    box-sizing: border-box;
    color: #fff;
    margin-right: 10px;
}

.itemPreview .status.error {
    background: red;
}

.itemPreview .status.success {
    background: #3bd23b;
}
.itemPreview .date {
    color: #3bd23b;
    margin: 0 10px;
}

.itemPreview .buttons {
    margin-left: auto;
    display: flex;
}
.itemPreview .buttons .button:not(:last-child) {
    margin-right: 20px;
}
.itemPreview .button {
    background: #3bd23b;
    color: #fff;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #3bd23b;
    box-sizing: border-box;
    transition: .3s all;
    padding: 10px;
}

.itemPreview .button:hover {
    background: #fff;
    color: #3bd23b;
    cursor: pointer;
}
