.contentWrap {
	margin: 14px 10px;
	padding: 11px 0;
	box-sizing: border-box;
}

.contentOverlay {
	position: fixed;
	width: 100%;
	height: 100%;
	display: block;
	left: 0;
	top: 0;
	z-index: 105;
	background: rgba(0, 0, 0, .3);
}

.contentPreload {
	position: absolute;
	left: 0;
	top: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	right: 0;
	bottom: 0;
	background: rgba(255, 255, 255, 0.6);
	z-index: 99;
}

.buttonText {
	text-align: left;
	margin-left: 12px;
	margin-bottom: -3px;
}

.buttonScenarioText {
	font-size: 18px;
	color: #398bdf;
}

.buttonMissionText {
	color: #3d3d3d;
	font-size: 24px;
}

.button:hover svg, .button.active svg {
	color: #398bdf !important;
}

.button {
	width: 38px;
	height: 38px;
}

.missionNavButton {
	display: flex;
	justify-content: center;
	align-items: center;
}

.button svg {
	transition: .3s all;
	cursor: pointer;
}

.missionNavigationButton {
	display: flex;
	align-items: center;
}

.missionNavigationDropdown {
	width: 720px;
	padding: 15px;
	box-sizing: border-box;
	height: 340px;
	background: #fff;
	justify-content: space-between;
	z-index: 105;
	border: 1px solid #cfcfcf;
	display: flex;
	margin-top: 28px;
	left: 28px;
	position: absolute;
}

.missionsDropdownContent:after {
	content: "";
	width: 1px;
	background: #ebeaea;
	height: 100%;
	position: absolute;
	right: -14px;
	top: 0;
}

.missionsDropdownContent, .scenariosDropdownContent {
	flex-basis: 48%;
	max-width: 48%;
	position: relative;
}

.dropdownContent {
	width: 100%;
	height: 250px;
	overflow-y: scroll;
}

.dropdownTitle {
	font-size: 14px;
	color: #398bdf;
	margin-bottom: 25px;
}
.dropdownContentElement .scenarioIcon{
	color: #3d3d3d;
	margin-right: 12px;
	transition: .3s all;
}
.dropdownContentElement:hover .scenarioIcon, 
.dropdownContentElement.active .scenarioIcon {
	color: #398bdf;
}


.dropdownContentElement .elementIcon img, .dropdownContentElement .elementIcon svg {
	width: 31px;
	height: 31px;
	transition: .3s color;
	color: #398bdf;
}

.dropdownContentElement .elementIcon {
	margin-right: 12px;
	background: transparent;
	height: 31px;
	flex-basis: 31px;
	min-width: 31px;
	display: flex;
	transition: .3s background;
	justify-content: center;
	align-items: center;
}

.dropdownContentElement .elementCounter {
	font-size: 14px;
	background: #cfcfcf;
	transition: .3s color, .3s background;
	border-radius: 15px;
	margin-left: auto;
	padding: 5px 10px;
	box-sizing: border-box;
}

.dropdownContentElement .elementName {
	font-size: 16px;
	transition: .3s color;
	color: #3d3d3d;
	text-align: left !important;
}

.dropdownContentElement .elementRetailerName {
	display: block;
	width: 100%;
	font-size: 12px;
}

.dropdownContentElement {
	display: flex;
	transition: .3s background;
	box-sizing: border-box;
	padding: 7px 9px;
	align-items: center;
}

.dropdownContentElement:hover .elementName, .dropdownContentElement.active .elementName {
	color: #398bdf;
}

.dropdownContentElement:hover .elementCounter, .dropdownContentElement.active .elementCounter {
	background: #398bdf;
	color: #fff;
}

.defaultIcon {
	display: flex;
}

.dropdownContentElement .elementIcon .defaultIcon svg {
	color: #3d3d3d;
	transition: .3s all;
}

.dropdownContentElement:hover .elementIcon .defaultIcon svg,
.dropdownContentElement.active .elementIcon .defaultIcon svg {
	fill: #398bdf;
}

.dropdownContentElement .elementIcon div:not(.defaultIcon) svg path {
	transition: .3s all;
	fill: #3d3d3d
}

.dropdownContentElement:hover .elementIcon div svg path,
.dropdownContentElement.active .elementIcon div svg path {
	fill: #398bdf;
}


.dropdownContentElement:hover {
	cursor: pointer;
}

.dropdownContentElement.active {
	background: #ebebeb;
}

.dropdownContentElementDisabled {
	opacity: .4;
}

.dropdownContentElementDisabled .elementName {
	color: #000 !important;
}

.dropdownContentElementDisabled:hover, .dropdownContentElementDisabled.active {
	background: #ffeaec !important;
}

.dropdownContentElementDisabled .elementCounter {
	background: #fd2727 !important;
	color: #fff !important;
}

.missionNavigationDropdown:before {
	content: " ";
	position: absolute;
	top: -10px;
	left: 24px;
	width: 0;
	height: 0;
	border-left: 10px solid transparent;
	border-right: 10px solid transparent;
	border-bottom: 10px solid white;
	z-index: 1;
}
.refreshButton {
	position: absolute;
	right: 10px;
	top: 10px;
	z-index: 10;
}
.refreshButton svg {
	transition: .3s all;
}
.refreshButton:hover {
	cursor: pointer;
}
.refreshButton:hover svg {
	transform: rotate(90deg);
}

@media screen and (max-width: 768px) {
	.missionNavigationDropdown:before {
		left: 62px;
	}

	.missionNavigationDropdown:after {
		left: 60px;
	}

	.missionNavigationDropdown {
		left: 0;
		width: 100%;
		flex-wrap: wrap;
		height: auto;
		margin-bottom: 25px;
	}

	.missionsDropdownContent, .scenariosDropdownContent {
		flex-basis: 100%;
		max-width: 100%;
		position: relative;
	}

	.missionsDropdownContent {
		margin-bottom: 25px;
	}

	.dropdownContent {
		height: auto;
	}

	.dropdownTitle {
		font-size: 22px;
		margin-bottom: 15px;
	}

	.contentPreload {
		align-items: flex-start;
		padding-top: 100px;
	}

	.buttonScenarioText {
		font-size: 16px;
	}

	.buttonMissionText {
		font-size: 20px;
	}
}
