.widgetWrap {
    padding: 0;
    position: relative;
    z-index: 3;
    float: left;
    width: 100%;
}
.updatesButton:hover {
    cursor: pointer;
}
.updatesButton {
    position: absolute;
    right: 15px;
    top: 5px;
    z-index: 5;
}