.tabs {
    padding: 14px 14px 0;
}

.content {
    pointer-events: none;
    opacity: 0.7;
    background: #EBEBEB;
}

.button {
    cursor: pointer;
    padding: 5px 10px;
}

.activeButton {
    background-color: #398bdf;
    color: #ffffff;
}