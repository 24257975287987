.buttonCancel {
    background-color: #b4b4b4;
    color: #ffffff !important;;
}

.buttonOk {
    background-color: #ae4954;
    color: #ffffff !important;
}

.buttonOk:hover {
    background-color: #e45b68 !important;
}

@media screen and (max-width: 480px) {
	.buttonOk {
		width: 100%;
		margin: 0;
	}
	.buttonOk:not(:last-of-type) {
		margin-bottom: 5px;
	}
}

.errorDialog {
    font-size: 16px;
}
.errorDialog section {
    background-color: #ffedef;
    border-bottom: 1px solid #e8bcc1;
    color: #000;
    overflow: auto;
}

.errorDialog section h6 {
    border-bottom: 1px solid #e8bcc1;
    color: #770613;
    padding-bottom: calc(1.6 * var(--unit));
}
