.tableWrap {
    box-shadow: 0 0 3px rgba(7, 62, 105, 0.4);
  }
  
  .tableWrapEmpty {
    box-shadow: 0 0 3px rgba(7, 62, 105, 0.4);
    text-align: center;
    padding: 30px;
  }
  
  .bottomBlock {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
  }
  
  .actionWrap {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
  }
  
  .buttonWrap {
    display: flex;
    /*max-height: 38px;*/
    right: 30px;
    position: absolute;
    top: 0;
    margin: 2px 10px 14px 10px;
    padding: 11px 0;
  }
  
  .buttonGroupWrap {
    display: flex;
    justify-content: flex-end;
    padding-left: 0;
    list-style: none;
    border-radius: 0.25rem;
    white-space: nowrap;
  }
  
  .buttonLink:hover {
    background-color: rgba(28, 113, 180, 0.08);
  }
  
  .buttonLink:first-child {
    margin-left: 0;
    border-bottom-left-radius: 0.25rem;
    border-top-left-radius: 0.25rem;
  }
  
  .buttonLink:last-child {
    border-bottom-right-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }
  
  .buttonLink.active {
    z-index: 2;
    color: #89a0c7;
    background-color: #e5efff;
  }
  
  .buttonLink {
    position: relative;
    display: block;
    padding: 1rem 1.5rem;
    margin-left: -1px;
    line-height: 1.25;
    color: #1c71b4;
    background-color: #fff;
    border: 1px solid #d4dff0;
  }
  
  .empty {
    display: block;
    text-align: center;
    padding: 80px 20px;
    font-size: 14px;
    color: black;
  }
  
  .addCaseText {
    color: #1c71b4;
  }
  
  .actionWrapSelect {
    min-width: 100% !important;
  }
  
  @media (max-width: 767px) {
    .buttonWrapMob {
      width: 100%;
      display: flex;
      height: 55px;
      flex-direction: row;
      justify-content: center;
      position: fixed;
      bottom: 0;
      left: 0;
      padding-top: 4px;
      background-color: #ffffff;
      z-index: 4;
      padding-bottom: 5px;
      box-shadow: 0px -3px 10px -4px rgba(64,64,64,1);
      margin-top: 7px;
  }
  
    .actionWrapSelect {
      min-width: 100% !important;
    }
    .tableWrap {
      overflow: hidden;
    }
    .tableWrapEmpty {
      margin: 0 10px;
    }
    .actionWrap {
      display: block;
      margin: 0 auto;
      width: 82%;
    }
    .actionWrapSelect {
      min-width: 100% !important;
      display: block;
      padding-bottom: 10px;
    }
    .tableContainer {
      padding: 10px;
    }
    .buttonGroupWrap {
      width: 22% !important;
      border: none;
      align-items: center;
    }
    .buttonLink {
      color: #000000 !important;
      border: none;
      align-items: center;
      font-weight: bold;
      width: 100%;
      display: flex !important;
      flex-direction: column !important;
      justify-content: flex-start !important;
      align-items: center;
      padding: 0;
      position: relative;
      bottom: -6px;
      min-width: 80px;
      min-height: 51px;
    }
    .addCaseText {
      font-size: 8px;
      color: #000000;
    }
    .bottomBlock {
      justify-content: center;
    }
  }
  