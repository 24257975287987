.container {
  display: flex;
  overflow: visible;
  align-items: center;
  position: absolute;
  top: 0;
  right: 105px;
  margin: 2px 10px 14px 10px;
  padding: 11px 0;
  gap: 10px;
}

.containerMob {
  display: flex;
  position: relative;
  overflow: visible;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  margin: 20px 20px 0;
}

.containerPickers {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: space-between;
  width: 100%;
}

.datePickerWrap {
  /*margin-top: 5px;*/
  /*margin-right: 10px;*/
  min-width: 80px;
}

.buttonWrap {
  display: block;
  width: 100%;
}

/* @media (max-width: 1240px) and (max-width: 800px) { */
@media (min-width: 768px) {
  .buttonWrap {
    width: auto;
  }
  .container {
    padding: 20px;
    margin: 0;
    right: 0;
  }
}