.row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: stretch;
    padding: 5px 0;
}

.column {
    display: flex;
    align-items: center;
    width: 60px;
}

.columnIcon {
    composes: column;
    width: 26px;
    line-height: 30px;
    text-align: center;
    padding: 0 5px;
}

.iconWrap {
    display: inline-block;
    width: 26px;
    line-height: 45px;
    color: #0072b9;
}

.columnName {
    composes: column;
    font-size: 14px;
    line-height: 16px;
    padding: 0 7px;
    color: #464646;
}

/**/

.container {
    padding: 14px;
    position: relative;
    overflow: visible;
}

.container:not(:first-child) {
    padding-top: 0;
}

.inner {
    display: block;
}

.inner[data-all-columns-mode] {
    z-index: 100;
    position: relative;
    box-shadow: 0 1px 18px rgba(137, 137, 137, .5);
}

/**/

.content {
    background-color: #ffffff;
    box-shadow: 0 1px 2px rgba(137, 137, 137, .5);
    display: none;
    height: 0;
    overflow: hidden;
    transition: height 600ms ease-in-out;
    position: relative;
    /*max-height: 300px;*/
}

[data-content-is-opened] .content {
    display: block;
}

/**/

.head {
    composes: row;
    height: 45px;
    background: #fafafa;
    padding: 0 5px;
    border-bottom: 1px solid #d0dde4;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    z-index: 1;
}

.contentIsOpened {
    height: auto;
}

.headColumn + .headColumn {
    display: none;
}

[data-inner-is-opened] .headColumn + .headColumn {
    display: flex;
}

.headIcon {
    composes: columnIcon;
    width: 32px;
    padding: 0;
}

.headIconClickable {
    cursor: pointer;
}

.headIconWrap {
    composes: iconWrap;
    color: #7396ac;;
    font-size: 22px;
    width: 32px;
}

.headIcon:hover .headIconWrap {
    color: #4f6f84;
}

.headName {
    composes: columnName;
    color: #004060;
    font-weight: bold;
    flex: auto;
    margin-right: 3px;
}

.headColumn {
    composes: column;
    width: 60px;
    color: #004060;
    margin-left: 4px;
    line-height: 14px;
    justify-content: center;
    font-weight: bold;
}

.clearButton {
    margin-left: 10px;
    color: #1c70b4;
    font-weight: bold;
    opacity: 0.8;
    cursor: pointer;
}

.clearButton:hover {
    opacity: 1;
}

/**/

.loading {
    display: block;
    text-align: center;
    padding: 18px 0 13px 0;
}

.updating {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.5);
    z-index: 3;
}


.progressIcon {
    display: inline-block;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -10px;
    margin-top: -10px;
}

.displayWrap {
    background-color: #f7f7f7;
}