@font-face {
  font-family: MuseoSansRounded;
  font-weight: 300;
  src:
    url("fonts/MuseoSansRounded/MuseoSansRounded-300.woff2") format("woff2"),
    url("fonts/MuseoSansRounded/MuseoSansRounded-300.woff") format("woff"),
    url("fonts/MuseoSansRounded/MuseoSansRounded-300.otf");
}

@font-face {
  font-family: MuseoSansRounded;
  font-weight: 500;
  src:
    url("fonts/MuseoSansRounded/MuseoSansRounded-500.woff2") format("woff2"),
    url("fonts/MuseoSansRounded/MuseoSansRounded-500.woff") format("woff"),
    url("fonts/MuseoSansRounded/MuseoSansRounded-500.otf");
}

@font-face {
  font-family: MuseoSansRounded;
  font-weight: 700;
  src:
    url("fonts/MuseoSansRounded/MuseoSansRounded-700.woff2") format("woff2"),
    url("fonts/MuseoSansRounded/MuseoSansRounded-700.woff") format("woff"),
    url("fonts/MuseoSansRounded/MuseoSansRounded-700.otf");
}