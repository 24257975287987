.imageInput {
    cursor: pointer;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    opacity: 0;
    overflow: hidden;
}

.actionButton{
    text-transform: uppercase !important;
    background-color: #fff !important;
}

.menuItem {
    /*font-size: 1.4rem !important;*/
    /*justify-content: center !important;*/
}