.container {
    display: block;
}

.preload {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    background: rgba(0, 0, 0, 0.1);
}

.preloadInner {
    /*display: inline-block;*/
    /*position: absolute;*/
    /*text-align: center;*/
    /*left: 50%;*/
    /*top: 50%;*/
    width: 40px;
    height: 40px;
    /*margin-left: -20px;*/
    /*margin-top: -20px;*/
}