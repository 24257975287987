.root {
  position: relative;
  margin-top: 6px;
  z-index: 3;
  width: 100% !important;
  display: flex;
}

.buttonMobile {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.buttons {
  width: 50%;
  padding: 5px !important;
  border-radius: 5px !important;
}

.root div:nth-child(1)::before {
  border-bottom: 0px solid #ffffff !important;
}

.selectShop {
  margin-right: 15px;
  font-size: 14px !important;
  height: 40px;
}

.chevronIcon {
  width: 0;
  height: 0;
  border: none;
  outline: none;
  display: none;
}

.shopNotActive {
  height: 40px;
  margin-right: 15px;
  font-size: 15px !important;
  color: #b2b2b2 !important;
}

.shopNotActive svg {
  display: none;
}

.root .shopNotActive div:hover {
  cursor: not-allowed !important;
}

.copyButton {
  border-top: 1px solid #c5c5c5 !important;
  border-right: 1px solid #c5c5c5 !important;
  border-bottom: 1px solid #c5c5c5 !important;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  margin-left: 1px;
}

.icon {
  position: absolute;
  right: 5px;
  top: 8px;
  color: #757575;
  z-index: 0;
}

@media (max-width: 767px) {
  .root {
    width: 100% !important;
    display: flex;
    flex-direction: column;
    height: auto;
    margin-bottom: 25px;
  }
  .root div {
    width: 100%;
    padding-right: 0;
  }
  .icon {
    position: absolute;
    text-align: right;
    right: 0px;
    color: #757575;
    z-index: 0;
  }
  .menuItem div {
    overflow: hidden !important;
    width: 90%;
    white-space: pre-wrap !important;
    font-size: 13px;
  }
  menuItem {
    display: flex;
    align-items: center;
  }
  div ul li {
    /*border-bottom: 0.5px solid #c6c6c6;*/
    padding: 5px 0px 5px 0px;
  }
  .copyButton {
    border: 1px solid #c5c5c5 !important;
    border-radius: 3px;
    margin-top: 4px;
    max-width: 50px;
  }
  .copyButton button {
    margin: 2px;
  }
}

@media (max-width: 321px) {
  .menuItem div span {
    font-size: 12px;
  }
  .root div {
    width: 100%;
    padding-right: 0;
  }
  .icon {
    position: absolute;
    text-align: right;
    right: 0;
    color: #757575;
    z-index: 0;
  }
}

.menuItem {
  overflow: visible !important;
  width: 100% !important;
  border-bottom: 0.5px solid #c5c5c5;
  white-space: normal !important;
}

.menuItem.selected,
.menuItem.selected:hover {
  background: transparent !important;
  background-color: #FFEDEC !important;
}
.menuItem:hover {
  background: transparent !important;
  background-color: #EFF4FE !important;
}
.menuItem {
  font-size: 15px !important;
  font-weight: 400 !important;
  transition: 0.3s color !important;
  border-bottom: 0.5px solid #c5c5c5 !important;
}

.root div > div > div {
  margin: 0 !important;
}

.textField.disabled:hover {
  cursor: not-allowed;
}

.textField.disabled div {
  pointer-events: none;
}

.textField.disabled input {
  color: rgba(0, 0, 0, 0.3);
}

.textField.notFound div:after {
  border-bottom-color: red;
}

.root .selectShop div {
  background-color: #ffff !important;
}
