.listBtn {
    border: 10px;
    box-sizing: border-box;
    display: block;
    font-family: Roboto, sans-serif;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    cursor: pointer;
    text-decoration: none;
    margin: 0px;
    padding: 0px;
    outline: none;
    font-size: 16px;
    font-weight: inherit;
    position: relative;
    color: rgba(0, 0, 0, 0.87);
    line-height: 48px;
    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    min-height: 48px;
    white-space: nowrap;
    background: none;
    width: 100%;
}

.popover {
    height: auto !important;
    padding: 20px;
    width: 300px;
}

.textField{
    margin-bottom: 5px !important;
}
.actionButton {
    display: flex;
    justify-content: space-between;
}
.actionButton button {
    text-transform: uppercase !important;
 }

.listBtn:hover {
    background-color: rgba(0, 0, 0, .1);
}

.imageInput {
    cursor: pointer;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    opacity: 0;
    overflow: hidden;
}

.btnWrap {
    display: block;
    position: relative;
}

.btnWrapInput {
    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    display: block;
    position: relative;
    background-color: white;
}

.btnWrapInput:hover {
    background-color: rgba(0, 0, 0, .1);
}