.button {
  margin-right: 10px !important;
}

.button span {
  color: #1c71b4 !important;
}

.buttonActive span {
  color: #ffffff !important;
}

@media (max-width: 767px) {
  .button {
    align-items: center;
    width: 22% !important;
    border: none !important;
    margin: 0 !important;
    padding: 6px 16px !important;
  }
  .button > span {
    flex-direction: column;
    height: 50px !important;
    font-size: 8px !important;
    font-weight: bold !important;
    color: #000 !important;
  }
  .button > span > div {
    margin-bottom: 2px !important;
    display: flex;
    order: -1;
  }
  .buttonActive {
    fill: #47b3e7 !important;
    background-color: transparent !important;
    box-shadow: none !important;
  }
  .buttonActive > span > span {
    color: #000 !important;
  }
}
