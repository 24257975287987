.row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: stretch;
}

.column {
    display: flex;
    align-items: center;
    width: 60px;
}

.columnIcon {
    composes: column;
    width: 26px;
    line-height: 30px;
    text-align: center;
    padding: 0 5px;
}

.iconWrap {
    display: inline-block;
    width: 26px;
    line-height: 45px;
    color: #0072b9;
}

.columnName {
    composes: column;
    font-size: 14px;
    line-height: 16px;
    padding: 0 7px;
    color: #464646;
}

/**/
.itemNameSingleOpen {
    composes: columnName;
    border-left: 1px solid #e0e0e0;
    color: #3d3d3d;
    padding: 10px;
    box-sizing: border-box;
    flex-basis: 85%;
    max-width: 85%;
    line-height: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
}

.itemNameOpen {
    composes: columnName;
    border-left: 1px solid #e0e0e0;
    color: #3d3d3d;
    padding: 10px;
    box-sizing: border-box;
    flex-basis: 175px;
    max-width: 175px;
    line-height: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
}

.item {
    composes: row;
    height: auto;
    margin-bottom: 0;
}

.item:hover {
    background-color: #ebebeb;
}
.itemIcon {
    composes: columnIcon;
    width: 32px;
}

.itemIconClickable {
    cursor: pointer;
}

.itemIconWrap {
    composes: iconWrap;
    color: #70acef;
}

.itemName {
    composes: columnName;
    border-left: 1px solid #e0e0e0;
    color: #3d3d3d;
    padding: 10px;
    box-sizing: border-box;
    flex-basis: 175px;
    max-width: 175px;
    line-height: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
}

@media (max-width: 767px) {
    .itemName {
        flex-basis: 250px;
        max-width: 250px;
    }
    .itemNameOpen {
        flex-basis: 175px;
        max-width: 175px;
    }
    .itemNameSingle {
        flex-basis: 250px;
        max-width: 250px;
    }
    .itemNameSingleOpen {
        flex-basis: 85%;
        max-width: 85%;
    }
}

@media (max-width: 320px) {
    .itemName {
        flex-basis: 190px;
        max-width: 190px;
    }
    .itemNameOpen {
        flex-basis: 175px;
        max-width: 175px;
    }
    .itemNameSingle {
        flex-basis: 190px;
        max-width: 190px;
    }
    .itemNameSingleOpen {
        flex-basis: 85%;
        max-width: 85%;
    }
}

.itemNameSingle {
    composes: columnName;
    border-left: 1px solid #e0e0e0;
    color: #3d3d3d;
    padding: 10px;
    box-sizing: border-box;
    flex-basis: 85%;
    max-width: 85%;
    line-height: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
}

.item .itemNameLastLevel {
    color: #adadad;
}

.item:hover .itemNameLevel {
    cursor: pointer;
    text-decoration: underline;
}

.itemCheckbox {
    composes: columnName;
    display: flex !important;
    align-self: center;
    max-width: 39px;
    flex-basis: 39px;
    padding: 10px 10px 10px 6px;
    line-height: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
    text-align: left;
    box-sizing: border-box;
}

/*.itemName:not(:last-child) {*/
    /*border-right: 1px solid #e0e0e0;*/
/*}*/

.itemNameIsActive {
    color: #398bdf;
}

/*.itemNameIsActive:hover {*/
    /*background: #51a9ff;*/
    /*color: #fff;*/
/*}*/

.itemColumn {
    composes: column;
    border-left: 1px solid #e0e0e0;
    width: auto;
    flex: 1 1;
    color: #3d3d3d;
    justify-content: center;
    font-size: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.itemIcon:hover .itemIconWrap {
    color: #003d5f;
}

.itemColumn + .itemColumn {
    display: none;
}

[data-all-columns-mode] .itemColumn + .itemColumn {
    display: flex;
}

.empty {
    display: block;
    text-align: center;
    padding: 10px;
    font-size: 12px;
}

[data-all-columns-mode] .itemColumn {
    flex: 1 1 65px;
    max-width: 65px;
    width: 0;
}
[data-all-columns-mode] .itemColumn:last-of-type {
    flex: 1 1 auto;
    max-width: none;
    width: 0;
}
