.container {
    padding: 14px;
    position: relative;
    overflow: visible;
}

/**/

.row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: stretch;
    padding: 0;
}

.column {
    display: flex;
    align-items: center;
    width: 60px;
    cursor: default;
}

.columnName {
    composes: column;
    font-size: 14px;
    line-height: 14px;
    padding: 0 5px;
    color: #464646;
}

/**/

.head {
    composes: row;
    /*height: 30px;*/
    background: #ebebeb;
    padding: 0 5px;
    font-size: 12px;
    border-bottom: 1px solid #d0dde4;
    margin-top: 5px;
}

.headColumn + .headColumn {
    display: none;
}

[data-all-columns-mode] .headColumn + .headColumn {
    display: flex;
}

.headName {
    composes: columnName;
    color: #4c4c4c;
    font-weight: bold;
    flex: auto;
    font-size: 12px;
    margin-right: 3px;
}

.headColumn {
    composes: column;
    width: 60px;
    color: #4c4c4c;
    font-size: 10px;
    line-height: 10px;
    justify-content: center;
    font-weight: normal;
    padding: 5px;
}

.headColumnText {
    display: block;
    white-space: pre-wrap;
    word-break: break-word;
    word-wrap: break-word;
    width: 100%;
}

/**/

.item {
    composes: row;
    height: auto;
    background: #f7f7f7;
    border: solid 1px #e0e0e0;
    border-radius: 2px;
    margin-top: 5px;
}

.itemName {
    composes: columnName;
    border-left: solid 1px #e0e0e0;
    color: #3d3d3d;
    padding: 7px;
    line-height: 14px;
    flex: auto;
    margin-right: 3px;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
}

.itemColumn {
    composes: column;
    border-left: solid 1px #e0e0e0;
    width: 60px;
    color: #3d3d3d;
    justify-content: flex-start;
    padding: 5px;
    font-weight: normal;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
}



.itemColumn + .itemColumn {
    display: none;
}

[data-all-columns-mode] .itemColumn + .itemColumn {
    display: flex;
}

[data-all-columns-mode] .columnName {
    max-width: 148px;
    flex-basis: 148px;
    margin-right: 0;
}
[data-all-columns-mode] .itemName {
    max-width: 125px;
    flex-basis: 125px;
}
[data-all-columns-mode] .headColumn {
    flex: 0 1 60px;
    max-width: 60px;
    width: auto;
}
[data-all-columns-mode] .headColumn:last-of-type {
    flex: 1 1 auto;
    max-width: none;
    width: 0;
}

[data-all-columns-mode] .itemColumn {
    flex: 0 1 59px;
    max-width: 59px;
    width: auto;
}

[data-all-columns-mode] .itemColumn:last-of-type {
    flex: 1 1 auto;
}

