.listItem {
    transition-duration: 0s;
    transition-property: none;
    transition-timing-function: linear;
    transition: background 0.2s;
    background: #fff;
}

.listItem:hover {
    background: #efefef;
}

.draggingListItem {
    background: rgb(235,235,235)
}

.itemControlRoot {
    width: 100%;
}

.itemCheckboxRoot {
    margin-right: 10px !important;
}

.dragIndicator {
    opacity: 0.5;
}