.wrap {
  min-height: calc(100vh - 92px);
  display: flex;
  align-items: center;
  justify-content: center;
}
.changePassword {
  display: block;
  max-width: 400px;
}
.inputWrap {
  width: 100%;
}
.buttonWrap {
  width: 100%;
  margin-top: 20px;
  display: flex;
}
.title {
  text-align: center;
  font-size: 24px;
}
.subtitle {
  font-size: 14px;
  margin-top: 10px;
  font-style: italic;
}
