.wrap {
	border: 1px solid #f2f0f0;
	border-top: 2px solid #f2f0f0;
	box-shadow: 0 1px 2px rgba(137, 137, 137, .5);
	margin: 0px 10px 20px 10px;
	padding: 10px 20px;
	position: relative;
	transition: all 500ms;
}

.wrap:hover {
	border-top: 2px solid #398bdf;
	box-shadow: 0 1px 8px rgba(137, 137, 137, .5);
}

.tableName {
	font-size: 18px;
	color: #293258;
	text-align: center;
	width: 100%;
	font-weight: 500;
}

.table {
	table-layout: fixed;
	width: 100%;
}

.table table {
	overflow: hidden;
}

.defaultCell {
	color: #3d3d3d;
	word-break: break-word;
}

.incPlus {
	color: #18ac04;
}

.incMinus {
	color: #ff002d;
}

.ableToDrill {
	color: #398bdf;
}

.ableToDrill:hover {
	text-decoration: underline;
	-webkit-text-decoration-color: #398bdf;
	        text-decoration-color: #398bdf;
	cursor: pointer;
}

.linkTranstion {
	font-style: italic;
}

.linkTranstion:hover {
	cursor: pointer;
	color: #000000;
	text-decoration: underline;
}

/*.topButtonsButton {*/
/*	position: absolute;*/
/*	top: -7px;*/
/*	padding: 0 !important;*/
/*	right: 15px;*/
/*	transition: .3s all;*/
/*}*/
/*.topButtonsButton svg {*/
/*	font-size: 20px !important;*/
/*	color: #3d3d3d;*/
/*	transition: .3s color;*/
/*}*/
/*.topButtonsButton:hover {*/
/*	background: none !important;*/
/*}*/
/*.topButtonsButton:hover svg {*/
/*	color: #398bdf !important;*/
/*}*/

thead:first-child{
	padding-left: 0px !important;
}


.table {
	border-right: 1px solid #e0e0e0;
}

.table div,  .wrap table td {
	color: #3d3d3d;
	font-size: 12px;
	text-align: center;
	justify-content: center;
}
.table tr {
	height: auto;
}
.table th, .wrap table td {
	border-right: 1px solid #e0e0e0;
	height: auto;
	line-height: 1.2;
	padding: 8px 10px;
}

.table td:nth-last-of-type(2),
.table th:nth-last-of-type(2),
.table td:last-of-type,
.table th:last-of-type
{
	border-right: 0;
}

.table th:first-of-type, .wrap table td:first-of-type {
	border-left: 1px solid #e0e0e0;
}
.table th {
	border-top: 1px solid #e0e0e0;
}
.table span {
	white-space: normal !important;
}


@media screen and (max-width: 767px) {
	.wrap {
		overflow-x: scroll;
	}
	.table {
		min-width: 767px;
	}

	.table td:nth-last-of-type(2),
	.table th:nth-last-of-type(2)
	{
		border-right: 1px solid #e0e0e0;
	}
}

.exportMenu {
	position: absolute;
	right: 20px;
}
.exportListItem {
	color: #3d3d3d;
	padding: 12px 16px;
	height: 24px;
	display: flex;
	justify-content: center;
	align-items: center;
}
.menuItem {
	padding: 0 !important;
	height: auto !important;
}
.arrowContainer{
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	width: 100% !important;
	overflow-wrap: break-word;
}
.textHeaderWrap{
	position: relative;
	max-width: 90%;
	text-align: center;
}
.arrows{
	width: 20%;
	display: flex;
	flex-direction: column !important;
	justify-content: center !important;
	align-items: center;
	position: absolute;
	right: -3px;
	cursor: pointer;
}
.wrapArrow{
	color: #e0e0e0 ;
}
.ascUp{
	color: #000000 !important ;
}
.descDown{
	color: #000000 !important ;
}

