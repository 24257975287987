.FiltersWrap {
  position: fixed;
  bottom: 0;
  right: 0;
  min-width: 60px;
  min-height: 7px;
  background-color: #398bdf;
  z-index: 100;
  opacity: 0.6;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  padding-top: 10px;
  padding-bottom: 9px;
  margin: 15px;
}

.filterClear {
  color: #ffffff;
}

.buttonHide {
  position: absolute;
  right: 3px;
  top: 5px;
  cursor: pointer;
  color: #ffffff;
}

.hideWrapper {
  background-color: red;
  min-width: 30px;
  height: 0px;
}

.openWrapper {
  padding: 5px 35px 5px 35px;
  color: #ffffff;
  text-align: center;
}

.buttonFilter {
  position: absolute;
  left: 0;
  top: 0;
}

.SvgWrap {
  position: absolute;
  top: 3px;
  left: 3px;
  fill: #398bdf;
}

.SvgWrapActive {
  position: absolute;
  top: 2px;
  left: 2px;
  fill: #398bdf;
  cursor: pointer;
}

.categoryWrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #ffffff;
  margin: 3px;
}

.categoriesFilters {
  text-align: center;
}

.checkboxesFilters {
  text-align: left;
  margin-left: 5px;
}
