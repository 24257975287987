@import 'fonts.css';

:root {
  /* override variables */
  --table-selection-color: #d5d5d5;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  /*font-size: 16px;*/
  height: 100%;
}

body {
  height: 100%;
  padding: 0;
  font-family: MuseoSansRounded, sans-serif;
  margin: 0 auto;
  min-width: 320px;
}

a {
  text-decoration: none;
}

p,
pre {
  padding: 0;
  margin: 0;
  /*font-size: 1.4rem;*/
}

pre {
  font-family: MuseoSansRounded, sans-serif;
}

button {
  font-family: MuseoSansRounded, sans-serif;
  border: none;
  background: transparent;
  outline: none;
  padding: 0;
}

ul,
ol {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.container {
  margin: 0 25px;
  position: relative;
}

@media (max-width: 767px) {
  .container {
    width: 100% !important;
    margin: 0 auto !important;
  }
}

@media (max-width: 1240px) {
  .container {
    /*width: 1240px;*/
    margin: 0 auto;
  }
}