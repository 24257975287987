.table {
	table-layout: fixed
}

.tableHeadRow .tableCell {
	color: #183f5d;
	font-weight: 700;
	text-align: center;
	padding: calc(var(--table-column-spacing) / 4);
}

.tableHead {
	border-right: 1px solid #d4dff0;
}

.tableRow {
	width: 100%;
	max-width: 100%;
}

.tableRow:hover {
	background-color: #f5fff8;
}

.tableRowStatus {
	background-color: #b9cdf1;
}

.finalCasesStyle {
	background-color: #89ffb0;
}

.manualCasesStyle {
	background-color: #f2d2d2;
}

.loading {
	opacity: .6;
}

.tableCell {
	border-right: 1px solid #d4dff0;
	padding: calc(var(--table-column-spacing) / 4);
	vertical-align: middle;
	text-align: left;
	font-size: 12px;
	line-height: 14px;
	letter-spacing: -0.2px;
	word-wrap: break-word;
	width: 40%;
}

.tableCell:first-of-type {
	padding-left: calc(var(--table-column-spacing) / 4);
	text-align: center;
	word-wrap: break-word;
	width: 30%;
}

.tableCell:last-of-type {
	padding-right: calc(var(--table-column-spacing) / 4);
	text-align: center;
	word-wrap: break-word;
	width: 30%;
}

.tableCell:last-child {
	border-right-color: transparent;
}

.tableHeadCell {
	font-size: 13px;
}

.textCenter {
	text-align: center;
}

.popover {
	height: auto !important;
	max-height: 220px !important;
}

.dialogRoot {
	display: flex;
	align-items: center;
	justify-content: center;
	padding-top: 0;
}

.dialogContent {
	position: relative;
	width: 80vw;
}

.selectedCaseContainer {
	display: block;
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	background: #fff;
	z-index: 100;
	overflow: hidden;
	overflow-y: auto;
	padding: 50px 10px 10px 10px;
}

.closeSelectedCase {
	display: inline-block;
	position: absolute;
	right: 0;
	top: 0;
	width: 48px;
	height: 48px;
}

.selectedCaseWrap {
	border: 1px solid #d9e2e9;
	padding: 5px;
}

.selectedCaseItem {
	font-size: 14px;
	border-top: 1px solid #e6e6e6;
	border-bottom: 1px solid #e6e6e6;
}

.selectedCaseItem:first-child {
	border-top: none;
}

.selectedCaseItem:last-child {
	border-bottom: none;
}

.infoPreload {
	text-align: center;
	padding: 15px;
}

.selectedCaseItemName {
	text-align: right;
	padding: 5px;
	color: #1c71b4;
	border-right: 1px solid #d2dff1;
}

.selectedCaseItemValue {
	text-align: left;
	padding: 5px;
	color: #000;
}

.controlWrap {
	padding: 15px 0;
	display: block;
	text-align: center;
}

.caseWithShops {
	max-height: 200px;
	height: 100%;
	overflow-y: scroll;
}

.caseWithShops span {
	display: block;
	color: #1c71b4;
}
