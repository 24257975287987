.chartWrap {
	padding: 0;
	float: left;
	width: 100%;
	position: relative;
}

.chart {
	border: 1px solid #f2f0f0;
	border-top: 2px solid #f2f0f0;
	box-shadow: 0 1px 2px rgba(137, 137, 137, .5);
	margin: 0px 10px 20px 10px;
	padding: 20px 10px;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	transition: all 500ms;
	position: relative;
}

.chart:hover {
	border-top: solid 2px #398bdf;
	box-shadow: 0 1px 8px rgba(137, 137, 137, .5);
}

.name {
	color: #293258;
	font-size: 18px;
}

.source {
	color: #6c95fe;
	font-size: 30px;
}

.unit {
	color: #293258;
	font-size: 14px;
}

.overlay {
	position: fixed;
	left: 0;
	top: 0;
	z-index: 9;
	width: 100%;
	height: 100%;
	display: block;
}

.colorPickerPlugin {
	position: absolute;
	z-index: 11;
	top: 45px;
	left: 20px
}

.updatesButton:hover {
	cursor: pointer;
}

.updatesButton {
	position: absolute;
	left: 10px;
	top: 10px;
	z-index: 5;
}

.topButtons {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	padding: 3px 25px 0 0;
	height: 3px;
	box-sizing: border-box;
}

.topButtons .topButtonsButton:last-of-type {
	margin-left: auto;
}

.topButtonsButton {
	z-index: 10;
	display: flex;
}

.topButtonsButton:hover {
	cursor: pointer;
}
.topButtonsIcon {
	font-size: 20px !important;
}



