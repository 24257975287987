.categoriesListWrap {
    box-shadow: 0 0 3px rgba(7, 62, 105, .4);
    display: flex;
    padding: 5px;
    position: relative;
    flex-wrap: wrap;
}

.categoryWrap {
    display: flex;
    width: 20%;
}

.categoryItem {
    display: flex;
    padding: 5px;
    width: 100%;
}

.category {
    padding: 10px !important;
    text-align: left !important;
    height: auto !important;
    line-height: normal;
    width: 100%;
    background-color: #1c71b4 !important;
    color: #ffffff !important;
}

.categoryActive {
    background-color: #248de2 !important;
}
.categoryActive span{
    display: block;
}

.categoryStep {
    text-align: right;
}

@media (max-width: 767px) {
    .categoryWrap {
        width: 50%;
    }
}