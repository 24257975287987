.element {
    margin-top: 20px;
}

.element .elementPreview * {
    font-size: 18px;
}
.element .elementPreview {
    display: flex;
    align-items: center;
}

.elementPreview .button {
    background: #3bd23b;
    color: #fff;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #3bd23b;
    box-sizing: border-box;
    margin-left: 20px;
    transition: .3s all;
    padding: 10px;
}

.elementPreview .button:hover {
    background: #fff;
    color: #3bd23b;
    cursor: pointer;
}

.element .elementBody {
    max-height: 200px;
    height: 100%;
    overflow-y: scroll;
}