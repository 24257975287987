.imageInput {
  cursor: pointer;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  opacity: 0;
  overflow: hidden;
}

.menuItem {
  justify-content: center !important;
}
