.container {
  padding: 0 14px 14px;
  position: relative;
  /* overflow: visible;  */
}

.datePickerWrap {
  background-color: #398bdf;
  border-top: 10px solid #398bdf;
  box-shadow: 0 1px 2px rgba(137, 137, 137, 0.5);
  padding: 10px;
}

.datePickerRow {
  color: #004a61;
  margin-right: 0;
  margin-left: 0;
  cursor: default;
}

.dateText {
  color: #fff;
  cursor: pointer;
}

.dateText > div {
  margin-top: 1px;
}

.pickersPopoverContainer {
  display: flex;
  flex-direction: column;
}
.pickersContainer {
  display: flex;
}

.pickersContainer > div:nth-child(1) > div:nth-child(1) > div:nth-child(1)::after {
  content: '';
  position: absolute;
  font-size: 3rem;
  color: #fff;
  top: 8px;
  right: 10px;
}
.pickersContainer > div:nth-child(2) > div:nth-child(1) > div:nth-child(1)::after {
  content: '';
  position: absolute;
  font-size: 3rem;
  color: #fff;
  top: 8px;
  right: 12px;
}

.pickersContols {
  margin: 10px;
  text-align: right;
}

@media screen and (max-width: 767px) {
  .fromToWrap {
    display: flex !important;
    flex-basis: 75%;
  }
  .iconToWrap {
    flex-basis: 15%;
  }
  .datePickerRow:first-child {
    margin-top: 4px !important;
    min-width: 35px;
    flex-basis: 35px;
  }
  .datePickerRow:nth-child(2) {
    padding-top: 1px;
  }
  .dateButton {
    margin-top: 4px;
  }
  .datePickerWrap {
    padding-bottom: 10px;
  }
  .pickersContainer {
    flex-direction: column;
  }
  .pickersContols {
    margin: 10px;
    text-align: center;
  }
  .dateText > div {
    margin-top: 1px;
  }
  .pickersContainer > div:nth-child(1) > div:nth-child(1) > div:nth-child(1)::after {
    font-size: 2rem;
    top: 16px;
    right: 20px;
  }
  .pickersContainer > div:nth-child(2) > div:nth-child(1) > div:nth-child(1)::after {
    font-size: 2rem;
    top: 16px;
    right: 20px;

  }
}
