.menuContainer {
    display: flex;
    justify-content: space-between;
    vertical-align: center;
    align-items: center;
}

.menuWrap {
    display: block;
    text-align: right;
    max-height: 300px;
}

.menuBeforeText {
    color: #3d3d3d;
    font-size: 14px;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-right: 12px;
}

.menuBeforeText span {
    font-weight: bold;
}

.dialogBody {
    height: 75vh;
    max-height: 600px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
}

.listWrap {
    display: block;
    overflow-y: auto;
    padding: 0;
}

.listItem {
    transition-duration: 0s;
    transition-property: none;
    transition-timing-function: linear;
    transition: background 0.2s;
    background: #fff;
}

.listItem:hover {
    background: #efefef;
}

.dialog {
    width: 500px;
}

@media (max-width: 767px) {
    .menuContainer {
        justify-content: start;
        align-items: start;
        padding-right: 20px;
    }

    .menuBeforeText {
        padding: 10px 0;
    }
}