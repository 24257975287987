.tabsWrap {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0px 13.5px 0px 0px;
    margin-left: 10px;
    position: relative;
    top: 2px;
}

.openWrap {
    display: flex;
    z-index: 10 !important;
}

.closeWrap {
    display: none;
}

.tabs {
    padding:  5px 5px 5px 5px;
    margin: 0px 6px 0px 0px;
    height: 29px;
    min-width: 25px;
    max-width: 200px;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    z-index: 0;
    opacity: 0.5;
    box-shadow: -2px 0px 2px -1.5px rgba(137, 137, 137, .5);
    background-color: #ffffff;
    border: 1px solid #E4E4E4;
    color: #00BDEE;
    border-bottom: 0px none #ffffff !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.tabs:hover {
    z-index: 11 !important;
}

.activeTabs {
    padding:  5px 5px 5px 5px;
    margin: 0px 6px 0px 0px;
    height: 29px;
    min-width: 70px;
    max-width: 200px;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    box-shadow: -2px 0px 2px -1.5px rgba(137, 137, 137, .5);
    z-index: 4;
    background-color: #ffffff;
    border-top: 2px solid #398bdf;
    color: #398bdf;
    border-bottom: 0px none #ffffff !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.containerTabs {
    position: relative;
}

@media screen and (max-width: 767px) {
	.tabsWrap button::before {
        min-width: 45%;
    }
    .tabsWrap {
        width: 92%;
        overflow: scroll;
    }
    .tabs {
        padding:  5px 5px 5px 5px;
        height: 28px;
        min-width: 65.5px;
        max-width: 100px;
        font-size: 11px;
    }
    .activeTabs {
        min-width: 65px;
        max-width: 100px;
        font-size: 11px;
    }
}

@media screen and (max-width: 320px) {
	.tabsWrap button::before {
        min-width: 80%;
    }

    .tabs {
        font-size: 11px;
    }
    
    .activeTabs {
        font-size: 11px;
    }
}

div table thead tr th:last-child {
    width: 0px;
}
