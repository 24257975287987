.container {
    position: fixed;
    width: 100%;
    height: 100vh;
    background-color: black;
    opacity: 0.2;
    top: 0;
    left: 0;
    z-index: 50;
    cursor: auto;
}

.questionMark {
    position: absolute;
    top: 15px;
    right: 5px;
    text-align: right;
}

.FAQpopup {
    position: absolute;
    z-index: 10;
    top: 5px;
    right: 5px;
    width: 440px;
    min-height: 150px;
    border: 1px solid #D8E1E8;
    border-radius: 3px;
    background-color: #ffffff;
    text-align: left;
}

@media (max-width: 426px) {
    .FAQpopup {
        width: 97%;
    }
}

.popupWrap {
    position: relative;
}

.FAQitems {
    position: relative;
    width: 80%;
    padding: 5px;
    margin-left: 40px;
    margin-right: 30px;
    margin-top: 5px;
    cursor: pointer;
    font-weight: 600;
    text-align: left;
}

.contentText:hover{
    cursor: pointer;
    color: #00A5E6;
}

.iconVideo {
    position: absolute;
    left: -30px;
    top: 2px;
    color: #004F83;
}

.iconClear {
    position: absolute;
    top: 5px;
    right: 5px;
    font-size: 15px !important;
}

.iconClearVideo {
    position: absolute;
    top: 7px;
    right: 7px;
    font-size: 24px !important;
}

.iconClear:hover {
    color: #3C6F95;
    cursor: pointer;
}

.iconFAQ {
    margin-top: 5px;
    color: #3C6F95;
    cursor: pointer;
}

.videoWrap {
    position: fixed;
    top: 40px;
    z-index: 100;
    right: 0;
    left: 0;
    margin: auto;
    width: 650px;
    height: 400px;
    background-color: #ffffff;
    padding: 40px 15px 10px 15px;
    border: 1px solid #D8E1E8;
    border-radius: 5px;
}

@media screen and (max-width: 813px) {
    .videoWrap {
        position: fixed;
        top: 0;
        z-index: 100;
        right: 0;
        left: 0;
        margin: auto;
        width: 93%;
        height: 300px;
        background-color: #ffffff;
        padding: 40px 15px 10px 15px;
        border: 1px solid #D8E1E8;
        border-radius: 5px;
    }
}