.widgetWrap {
	padding: 0;
	position: relative;
	z-index: 3;
	float: left;
	width: 100%;
}

.widget {
	border: 1px solid #f2f0f0;
	border-top: 2px solid #f2f0f0;
	box-shadow: 0 1px 2px rgba(137, 137, 137, .5);
	margin: 0px 10px 20px 10px;
	padding: 10px 20px;
	height: 120px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	transition: all 500ms;
	position: relative;
}

.widget:hover .underlined {
	text-decoration: underline;
}

.widget:hover {
	border-top: 2px solid #398bdf;
	box-shadow: 0 1px 8px rgba(137, 137, 137, .5);
}

.name {
	color: #3d3d3d;
	font-size: 17px;
	display: flex;
	align-items: center;
	transition: all 500ms;
}
.nameSub {
	cursor: pointer;
	color: #3d3d3d;
	font-size: 18px;
	display: flex;
	align-items: center;
	transition: all 500ms;
}

.widget:hover .name, .nameSub {
	color: #398bdf;
}

.source {
	color: #398bdf;
	line-height: normal;
	overflow: hidden;
	transition: all 500ms;
}

.hugeSourceSize {
	color: #398bdf;
	font-size: 23px;
	line-height: normal;
	overflow: hidden;
	transition: all 500ms;
}

.widget:hover .source {
	color: #398bdf;
}

.unit {
	color: #293258;
	font-size: 14px;
}

.icon {
	font-size: 18px !important;
	margin-right: 5px;
	color: #1c71b4;
}

.tooltipStyle {
	font-size: 12px !important;
}
