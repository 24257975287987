.tableHeadRow .tableCell {
	color: #183f5d;
	font-weight: 700;
	text-align: center;
	padding: calc(var(--table-column-spacing) / 4);
}

.tableHead {
	border-right: 1px solid #d4dff0;
}

.tableRow:hover {
	background-color: #f5fff8;
}

.tableRowStatus {
	background-color: #b9cdf1;
}

.finalCasesStyle {
	background-color: #89ffb0;
}

.manualCasesStyle {
	background-color: #f2d2d2;
}

.tableCell {
	border-right: 1px solid #d4dff0;
	padding: calc(var(--table-column-spacing) / 4);
}

.tableCell:first-of-type {
	padding-left: calc(var(--table-column-spacing) / 4);
}

.tableCell:last-of-type {
	padding-right: calc(var(--table-column-spacing) / 4);
}

.tableCell:last-child {
	border-right-color: transparent;
}

.textCenter {
	text-align: center;
}

.popover {
	height: auto !important;
	max-height: 220px !important;
}

.dialogRoot {
	display: flex;
	align-items: center;
	justify-content: center;
	padding-top: 0;
}

.dialogContent {
	position: relative;
	width: 80vw;
}

.progressIcon {
	display: inline-block;
	width: 24px;
	height: 24px;
	line-height: 24px;
	text-align: center;
	padding: 8px 0;
}

